<template>
<v-container>
  <v-row>
    <a class="py-4"
      :href="reportPage"
      target="_blank"
    >
      Open the report
    </a>
  </v-row>
  <v-row justify="center">
  <v-col cols="7">
  <v-card>
    <v-card-title>
      Auto Created Dependencies
    </v-card-title>
  <v-data-table
    :headers="headers"
    :items="dependencyList"
    :items-per-page="100"
    class="elevation-1"
    :hide-default-footer="true"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
      depressed
      color="primary"
      @click="redirectURL(item)">
      Click to Edit
      </v-btn>
    </template>
  </v-data-table>
  </v-card>
  </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  name: 'ImportAutoCreatedDependencyList',
  data() {
    return {
      dependencyList: [],
      headers: [
        { text: 'Type', value: 'type', align: 'center' },
        { text: 'Data', value: 'data', align: 'center' },
        { text: 'Actions', value: 'actions', align: 'center' },
      ],
      reportPage: '',
    };
  },
  created() {
    this.parseArgsAndFormatData();
    this.parseArgsForRedirectURL();
  },
  methods: {
    parseArgsForRedirectURL() {
      const { redirectToPage } = this.$route.params;
      this.reportPage = (redirectToPage || {}).url || '';
    },
    parseArgsAndFormatData() {
      const errorList = this.$route.params.args;
      if (!(errorList == null) && errorList.length > 0) {
        const dataList = JSON.parse(JSON.stringify(errorList));
        const urlMapper = [];
        // Todo - update for payroll
        urlMapper.sharedFund = 'Shared Program - Funds';
        urlMapper.warAccount = 'Warrant/Payroll Register - Accounts';
        urlMapper.warDepartment = 'Warrant/Payroll Register - Departments';

        for (let i = 0; i < (dataList || []).length; i += 1) {
          if (dataList[i].errorKey === 'sharedFund') {
            const fundsArr = JSON.parse(dataList[i].data);
            const { fundType } = dataList[i];
            for (let k = 0; k < (fundsArr || []).length; k += 1) {
              const paramsData = {};
              paramsData.type = dataList[i].errorKey;
              paramsData.data = fundsArr[k][`${fundType}`];
              paramsData.componentName = urlMapper[dataList[i].errorKey];
              this.dependencyList.push(paramsData);
            }
          } else {
            for (let j = 0; j < (dataList[i].data || []).length; j += 1) {
              const paramsData = {};
              paramsData.type = dataList[i].errorKey;
              paramsData.data = dataList[i].data[j];
              paramsData.componentName = urlMapper[dataList[i].errorKey];
              this.dependencyList.push(paramsData);
            }
          }
        }
      }
    },
    redirectURL(item) {
      const routeData = this.$router.resolve({ name: `${item.componentName}` });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
